<template>
  <v-container>
    <h1
      :style="welcomeTextFontSize"
      class="text-center font-arjun font-weight-bold mb-8"
      style="color: grey; letter-spacing: 2px"
    >{{welcomeText}}</h1>

    <v-expansion-panels accordion multiple>
      <v-expansion-panel class="custom-shadow">
        <v-expansion-panel-header>ඔබ සොයන්නාගේ විස්තර ඇතුළත් කරන්න</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="12" md="3" sm="6">
              <v-text-field
                @change="fetchMembers(true)"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="සාමාජික අංකය (ඔබ දන්නෙහි නම් පමණක්)"
                solo
                v-model="queryParams.username"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                :items="queryData.marriageStatuses"
                @change="fetchMembers(true)"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="status"
                label="විවාහක තත්වය"
                solo
                v-model="queryParams.marriageStatus"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                :items="queryData.countries"
                @change="fetchMembers(true)"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="ජීවත්වන රට"
                solo
                v-model="queryParams.livingCountry"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                :items="queryData.districts"
                @change="fetchMembers(true)"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="දිස්ත්‍රික්කය"
                solo
                v-model="queryParams.district"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-row no-gutters>
                <v-col cols="6">
                  <v-select
                    :items="queryData.heights"
                    @change="fetchMembers(true)"
                    background-color="#fafafa"
                    class="mx-1 my-1"
                    clearable
                    dense
                    flat
                    hide-details
                    item-text="value"
                    label="උස(සිට)"
                    solo
                    v-model="queryParams.heightFrom"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    :items="queryData.heights"
                    @change="fetchMembers(true)"
                    background-color="#fafafa"
                    class="mx-1 my-1"
                    clearable
                    dense
                    flat
                    hide-details
                    item-text="value"
                    label="උස(දක්වා)"
                    solo
                    v-model="queryParams.heightTo"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                :items="queryData.religions"
                @change="fetchMembers(true)"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="ආගම"
                solo
                v-model="queryParams.religion"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                :items="queryData.races"
                @change="fetchMembers(true)"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="ජාතිය"
                solo
                v-model="queryParams.race"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                :items="queryData.casts"
                @change="fetchMembers(true)"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="කුළය"
                solo
                v-model="queryParams.cast"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                :items="queryData.educationLevels"
                @change="fetchMembers(true)"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="level"
                label="අධ්‍යාපන මට්ටම"
                solo
                v-model="queryParams.educationLevel"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                :items="queryData.jobs"
                @change="fetchMembers(true)"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="රැකියා අංශය"
                solo
                v-model="queryParams.job"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                :items="queryData.monthlyIncomes"
                @change="fetchMembers(true)"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="value"
                label="මාසික ආදායම"
                solo
                v-model="queryParams.monthlyIncome"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-row no-gutters>
                <v-col cols="6">
                  <v-select
                    :items="queryData.numbersArray"
                    @change="fetchMembers(true)"
                    background-color="#fafafa"
                    class="my-1 mx-1"
                    clearable
                    dense
                    flat
                    hide-details
                    label="වයස(සිට)"
                    solo
                    v-model="queryParams.ageFrom"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    :items="queryData.numbersArray"
                    @change="fetchMembers(true)"
                    background-color="#fafafa"
                    class="my-1 mx-1"
                    clearable
                    dense
                    flat
                    hide-details
                    label="වයස(දක්වා)"
                    solo
                    v-model="queryParams.ageTo"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="custom-shadow" id="secondary-filter">
        <v-expansion-panel-header>තව දුරටත් සොයන්න</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :items="queryData.lagna"
                @change="fetchMembers(true)"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="ලග්නය"
                outlined
                v-model="queryParams.lagnaya"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :items="queryData.gana"
                @change="fetchMembers(true)"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="ගණය"
                outlined
                v-model="queryParams.ganaya"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :items="queryData.nekath"
                @change="fetchMembers(true)"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="නැකත"
                outlined
                v-model="queryParams.nekatha"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :items="queryData.oneToTwelve"
                @change="fetchMembers(true)"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                label="රවි"
                outlined
                v-model="queryParams.ravi"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :items="queryData.oneToTwelve"
                @change="fetchMembers(true)"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                label="චන්ද්‍ර"
                outlined
                v-model="queryParams.chandra"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :items="queryData.oneToTwelve"
                @change="fetchMembers(true)"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="කුජ"
                outlined
                v-model="queryParams.kuja"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :items="queryData.oneToTwelve"
                @change="fetchMembers(true)"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="බුධ"
                outlined
                v-model="queryParams.budha"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :items="queryData.oneToTwelve"
                @change="fetchMembers(true)"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="ගුරු"
                outlined
                v-model="queryParams.guru"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :items="queryData.oneToTwelve"
                @change="fetchMembers(true)"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="රාහු"
                outlined
                v-model="queryParams.rahu"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :items="queryData.oneToTwelve"
                @change="fetchMembers(true)"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="සිකුරු"
                outlined
                v-model="queryParams.sikuru"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :items="queryData.oneToTwelve"
                @change="fetchMembers(true)"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="ශනි"
                outlined
                v-model="queryParams.shani"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :items="queryData.oneToTwelve"
                @change="fetchMembers(true)"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="කේතු"
                outlined
                v-model="queryParams.kethu"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :items="['ඔව්', 'නැත', 'නොදනී']"
                @change="fetchMembers(true)"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="පාප කේන්දර"
                outlined
                v-model="queryParams.papaKendara"
              ></v-select>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row class="mt-4" no-gutters>
      <v-col
        :key="member.id"
        class="px-1 my-2"
        cols="6"
        lg="2"
        md="3"
        sm="4"
        v-for="member in members"
      >
        <MemberCard
          :age="member.age"
          :district="member.district"
          :gender="member.gender"
          :id="member.id"
          :profilePicture="member.photoUrl"
          :profPicShowStatus="member.profPicShowStatus"
        />
      </v-col>
    </v-row>
    <v-divider class="mt-0 mb-2"></v-divider>
    <v-pagination :length="length" @input="fetchMembers(false)" circle v-model="page"></v-pagination>

    <p
      class="body-2 text-center mt-6"
      style="color: #f95454"
    >යෝජනාවක් සාර්ථක වූ වහාම ආයතනය දැනුවත් කර ගිණුම ඉවත් කරගැනීම ඔබගේ වගකීමයි.</p>
  </v-container>
</template>

<script>
import { ApiService } from "../services/api.service";
import { TokenService } from "../services/storage.service";
import MemberCard from "../components/MemberCard";
import { mapState } from "vuex";
import { CHANGE_LOADING_STATE } from "../store/mutation-types";

export default {
  name: "members",
  components: {
    MemberCard
  },

  data: () => ({
    welcomeText: 'wdYs¾jdo ux., fiajh i|yd idorfhka ms<s.ksuq',
    members: [],
    perPage: 18,
    page: 1,
    length: 0,
    queryData: {
      marriageStatuses: [],
      countries: [],
      heights: [],
      races: [],
      religions: [],
      casts: [],
      monthlyIncomes: [],
      districts: [],
      educationLevels: [],
      jobs: [],
      lagna: [],
      gana: [],
      nekath: [],
      numbersArray: [
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
        53,
        54,
        55,
        56,
        57,
        58,
        59,
        60,
        61,
        62,
        63,
        64,
        65,
        66,
        67,
        68,
        69,
        70
      ],
      oneToTwelve: ["-", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]
    },
    queryParams: {
      username: "",
      marriageStatus: "",
      livingCountry: "",
      heightFrom: "",
      heightTo: "",
      race: "",
      religion: "",
      cast: "",
      monthlyIncome: "",
      district: "",
      educationLevel: "",
      job: "",
      lagnaya: "",
      ganaya: "",
      nekatha: "",
      ageFrom: "",
      ageTo: "",
      ravi: "",
      chandra: "",
      kuja: "",
      budha: "",
      guru: "",
      rahu: "",
      sikuru: "",
      shani: "",
      kethu: "",
      papaKendara: "",
    }
  }),

  mounted() {
    this.fetchMembers(false);
    this.fetchQueryData();
  },

  computed: {
    welcomeTextFontSize: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "font-size: 1.9em";
        case "sm":
          return "font-size: 2em";
        case "md":
          return "font-size: 2.2em";
        default:
          return "font-size: 2.5em";
      }
    },

    getBaseParams: function () {
      let params = {};
      const gender = TokenService.getToken().gen;

      if (gender == process.env.VUE_APP_MALE_VALUE) {
        params = { gender: "ස්ත්‍රී" };
      } else if (gender == process.env.VUE_APP_FEMALE_VALUE) {
        params = { gender: "පුරුෂ" };
      }

      return params;
    },

    getFilterQuery: function () {
      const usernameParam = this.queryParams.username
        ? { id: this.queryParams.username }
        : {};

      const marriageStatusParam = this.queryParams.marriageStatus
        ? { marriageStatus: this.queryParams.marriageStatus }
        : {};

      const livingCountryParam = this.queryParams.livingCountry
        ? { livingCountry: this.queryParams.livingCountry }
        : {};

      const heightFromParam = this.queryParams.heightFrom
        ? { heightFrom: this.queryParams.heightFrom }
        : {};
      const heightToParam = this.queryParams.heightTo
        ? { heightTo: this.queryParams.heightTo }
        : {};
      const raceParam = this.queryParams.race
        ? { race: this.queryParams.race }
        : {};

      const religionParam = this.queryParams.religion
        ? { religion: this.queryParams.religion }
        : {};

      const castParam = this.queryParams.cast
        ? { cast: this.queryParams.cast }
        : {};

      const monthlyIncomeParam = this.queryParams.monthlyIncome
        ? { monthlyIncome: this.queryParams.monthlyIncome }
        : {};

      const districtParam = this.queryParams.district
        ? { district: this.queryParams.district }
        : {};

      const educationLevelParam = this.queryParams.educationLevel
        ? { educationLevel: this.queryParams.educationLevel }
        : {};

      const jobParam = this.queryParams.job
        ? { job: this.queryParams.job }
        : {};

      const lagnaParam = this.queryParams.lagnaya
        ? { lagna: this.queryParams.lagnaya }
        : {};

      const ganayaParam = this.queryParams.ganaya
        ? { ganaya: this.queryParams.ganaya }
        : {};

      const nekathaParam = this.queryParams.nekatha
        ? { nekatha: this.queryParams.nekatha }
        : {};

      const ageFromParam = this.queryParams.ageFrom
        ? { ageFrom: this.queryParams.ageFrom }
        : {};

      const ageToParam = this.queryParams.ageTo
        ? { ageTo: this.queryParams.ageTo }
        : {};

      const raviParam = this.queryParams.ravi
        ? { ravi: this.queryParams.ravi }
        : {};

      const chandraParam = this.queryParams.chandra
        ? { chandra: this.queryParams.chandra }
        : {};

      const kujaParam = this.queryParams.kuja
        ? { kuja: this.queryParams.kuja }
        : {};

      const budhaParam = this.queryParams.budha
        ? { budha: this.queryParams.budha }
        : {};

      const guruParam = this.queryParams.guru
        ? { guru: this.queryParams.guru }
        : {};

      const rahuParam = this.queryParams.rahu
        ? { rahu: this.queryParams.rahu }
        : {};

      const sikuruParam = this.queryParams.sikuru
        ? { sikuru: this.queryParams.sikuru }
        : {};

      const shaniParam = this.queryParams.shani
        ? { shani: this.queryParams.shani }
        : {};

      const kethuParam = this.queryParams.kethu
        ? { kethu: this.queryParams.kethu }
        : {};

      const papaKendaraParam = this.queryParams.papaKendara
        ? { papaKendara: this.queryParams.papaKendara }
        : {};

      const params = Object.assign(
        usernameParam,
        marriageStatusParam,
        livingCountryParam,
        heightFromParam,
        heightToParam,
        religionParam,
        raceParam,
        castParam,
        monthlyIncomeParam,
        districtParam,
        educationLevelParam,
        jobParam,
        lagnaParam,
        ganayaParam,
        nekathaParam,
        ageFromParam,
        ageToParam,
        raviParam,
        chandraParam,
        kujaParam,
        budhaParam,
        guruParam,
        rahuParam,
        sikuruParam,
        shaniParam,
        kethuParam,
        papaKendaraParam,
        this.getBaseParams
      );

      return params;
    }
  },

  methods: {
    // Get query data
    fetchQueryData: async function () {
      try {
        const url = "metadata";
        const response = await ApiService.get(url);
        this.queryData.marriageStatuses = response.data.marriageStatuses.slice(1);
        this.queryData.countries = response.data.countries;
        this.queryData.heights = response.data.heights;
        this.queryData.religions = response.data.religions.slice(1);
        this.queryData.races = response.data.races.slice(1);
        this.queryData.casts = response.data.casts.slice(1);
        this.queryData.districts = response.data.districts.slice(1);
        this.queryData.educationLevels = response.data.educations.slice(1);
        this.queryData.jobs = response.data.jobs.slice(1);
        this.queryData.monthlyIncomes = response.data.monthlyIncomes.slice(1);
        this.queryData.lagna = response.data.lagna;
        this.queryData.gana = response.data.gana;
        this.queryData.nekath = response.data.nekath;
      } catch (error) {
        console.log(error);
      }
    },

    // Get members
    fetchMembers: async function (filter) {
      this.$store.commit(CHANGE_LOADING_STATE, { active: true });
      const mPage = filter ? 1 : this.page;

      try {
        const url = "customers";
        const params = {
          params: { ...this.getFilterQuery, approved: 1, perPage: this.perPage, page: mPage }
        }; // When checkRequests = true, member list will return with requests(photo/horoscope) details 
        const response = await ApiService.get("customers", params);
        this.members = response.data.data;
        this.page = response.data.meta.current_page;
        this.length = response.data.meta.total == 0 ? 0 : response.data.meta.last_page;
      } catch (error) {
        console.log(error);
      }
      this.$store.commit(CHANGE_LOADING_STATE, { active: false });
    }
  }
};
</script>

<style scoped>
#secondary-filter >>> .v-text-field--outlined fieldset {
  display: none;
}
</style>