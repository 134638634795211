<template>
  <v-hover v-slot:default="{ hover }">
    <v-sheet class="text-center" color="transparent">
      <v-overlay :value="hover" absolute>
        <v-btn :to="'/dashboard/members/'+ id" color="secondary" elevation="4" small>බලන්න</v-btn>
      </v-overlay>
      <v-avatar size="75">
        <v-img :src="image" aspect-ratio="1" class="mx-auto grey lighten-2" max-width="100">
          <template v-slot:placeholder>
            <v-row align="center" class="fill-height ma-0" justify="center">
              <v-progress-circular class="mx-auto my-auto" color="grey lighten-5" indeterminate></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-avatar>
      <p class="body-2">සා. අංකය: {{ id }}</p>
      <p class="subtitle-1 mt-n5">වයස: {{ age }}</p>
      <p class="body-2 text--secondary mt-n4 mb-3">{{district}}</p>
    </v-sheet>
  </v-hover>
</template>

<script>
import { ApiService } from "../services/api.service";
import { TokenService } from "../services/storage.service";
import { mapMutations } from "vuex";
import { SHOW_ALERT } from "../store/mutation-types";

export default {
  props: ["id", "age", "gender", "district", "profilePicture", "profPicShowStatus"],

  computed: {
    image: function () {
      if (this.profilePicture && (this.profPicShowStatus == 0 || this.profPicShowStatus == 1)) {
        return process.env.VUE_APP_BASE_URL + this.profilePicture;
      } else {
        if (this.gender == "ස්ත්‍රී") {
          return require("@/assets/images/female_user.png");
        } else {
          return require("@/assets/images/male_user.png");
        }
      }
    }
  }
};
</script>

<style scoped>
a:hover {
  text-decoration: none;
}
</style>